import { UserRoles } from '@/data/user/role';
import Feature from '@/domain/core/Feature';
import { Permission } from '@/features/permissions';
import { TUser } from '@/types/user';

export interface IHeaderTab {
	path: string;
	name: string;
	dataTestId: string;
	visible?: boolean;
}

interface IHeaderStore {
	tabsConfig: IHeaderTab[];
}

class HeaderStore implements IHeaderStore {
	constructor(public user: TUser) {
		this.user = user;
	}

	get defaultHeaderMenu() {
		return [
			{
				path: '/projects',
				dataTestId: 'projects',
				name: 'Проекты',
				visible: Permission.instance.canAccess('projectsListPage'),
			},
			{
				path: '/experts',
				dataTestId: 'experts',
				name: 'Эксперты',
				visible: Permission.instance.canAccess('expertsListPage'),
			},
			{
				path: `/experts/${this.user.id}/expertises/new`,
				dataTestId: 'experts',
				name: 'Экспертиза',
				visible: Permission.instance.canAccess('expertiseNewListPage'),
			},
			{
				path: '/users',
				dataTestId: 'users',
				name: 'Пользователи	',
				visible: Permission.instance.canAccess('usersListPage'),
			},
			{
				path: '/drafts',
				dataTestId: 'drafts',
				name: 'Черновики',
				visible: Permission.instance.canAccess('draftsPage'),
			},
			{
				path: '/reports',
				dataTestId: 'reports',
				name: 'Отчёты',
				visible: Feature.isFeatureEnabled('reportsPage') && Permission.instance.canAccess('reportsPage'),
			},
			{
				path: '/admin',
				dataTestId: 'admin',
				name: 'Настройки',
				visible:
					Feature.isFeatureEnabled('adminPage') &&
					Permission.instance.canAccessSome([
						'adminDictionaryPage',
						'adminSelectionPage',
						'adminBusinessProcessPage',
						'adminNotificationTemplatePage',
						'adminRoadmapTemplatePage',
						'adminCollegialPage',
						'adminRolePage',
						'adminProjectTemplatePage',
						'adminCriteriaPage',
						'adminConsentPage',
					]),
			},
		];
	}

	get tabsConfig() {
		if (!this.user.role.shouldWorkAsLegacyRole) {
			return this.defaultHeaderMenu;
		}

		switch (this.user.role?.name) {
			case UserRoles.Expert:
			case UserRoles.ThemeExpert:
				return [
					{
						path: `/experts/${this.user.id}/expertises/new`,
						dataTestId: 'experts',
						name: 'Экспертиза',
					},
				];
			case UserRoles.TopManager:
			case UserRoles.Admin:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертиза',
					},
					{
						path: '/users',
						dataTestId: 'users',
						name: 'Пользователи	',
					},
					{
						path: '/themes',
						dataTestId: 'themes',
						name: 'Темы',
					},
					{
						path: '/reports',
						dataTestId: 'reports',
						name: 'Отчёты',
						visible: Feature.isFeatureEnabled('reportsPage'),
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
					{
						path: '/admin',
						dataTestId: 'admin',
						name: 'Настройки',
					},
				];
			case UserRoles.ThemeAdmin:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертиза',
					},
					{
						path: '/users',
						dataTestId: 'users',
						name: 'Пользователи',
					},
					{
						path: '/reports',
						dataTestId: 'reports',
						name: 'Отчёты',
						visible: Feature.isFeatureEnabled('reportsPage'),
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
				];
			case UserRoles.GlobalAdmin:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертиза',
					},
					{
						path: '/users',
						dataTestId: 'users',
						name: 'Пользователи	',
					},
					{
						path: '/themes',
						dataTestId: 'themes',
						name: 'Темы',
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
					{
						path: '/reports',
						dataTestId: 'reports',
						name: 'Отчёты',
						visible: Feature.isFeatureEnabled('reportsPage'),
					},
					{
						path: '/admin',
						dataTestId: 'admin',
						name: 'Настройки',
					},
				];
			case UserRoles.Tracker:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертизы',
					},
					{
						path: '/themes',
						dataTestId: 'themes',
						name: 'Темы',
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
				];
			case UserRoles.ThemeTracker:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертизы',
					},
					{
						path: '/themes',
						dataTestId: 'themes',
						name: 'Темы',
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
				];
			case UserRoles.HeadTracker:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертизы',
					},
					{
						path: '/themes',
						dataTestId: 'themes',
						name: 'Темы',
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
				];
			case UserRoles.Leader:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/drafts',
						dataTestId: 'drafts',
						name: 'Черновики',
					},
				];
			case UserRoles.Manager:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/users',
						dataTestId: 'users',
						name: 'Пользователи	',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертиза',
					},
				];
			case UserRoles.Employee:
				return [
					{
						path: '/projects',
						dataTestId: 'projects',
						name: 'Проекты',
					},
					{
						path: '/users',
						dataTestId: 'users',
						name: 'Пользователи	',
					},
					{
						path: '/experts',
						dataTestId: 'experts',
						name: 'Экспертиза',
					},
				];
			default:
				return this.defaultHeaderMenu;
		}
	}
}

export default HeaderStore;

// eslint-disable-next-line no-shadow
export enum UserRoles {
	Admin = 'ISUP_admin', // Администратор
	GlobalAdmin = 'ISUP_global-admin', // Глобальный администратор
	SecurityAdmin = 'ISUP_security-admin', // Администратор ИБ
	Leader = 'ISUP_leader', // Лидер
	HeadTracker = 'ISUP_head-tracker', // Старший трекер
	ImportAdmin = 'ISUP_import_admin', // Импорт администратор
	Tracker = 'ISUP_tracker', // Трекер
	Expert = 'ISUP_expert', // Эксперт
	Employee = 'ISUP_employee', // Сотрудник АСИ
	Manager = 'ISUP_manager', // Руководитель АСИ,
	TopManager = 'ISUP_top-manager', // Топ менеджер с возможность только чтения
	// Международные
	InternationalAdmin = 'ISUP_international_admin',
	InternationalLeader = 'ISUP_international_leader',
	InternationalExpert = 'ISUP_international_expert',
	InternationalTracker = 'ISUP_international_tracker',
	InternationalTrackerExpert = 'ISUP_international_tracker_expert',
	ThemeExpert = 'ISUP_theme_expert',
	ThemeAdmin = 'ISUP_theme_admin',
	ThemeTracker = 'ISUP_theme_tracker',
}

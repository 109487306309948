import { makeAutoObservable, runInAction } from 'mobx';

import { UserRoles } from '@/data/user/role';
import { RolePermissions } from '@/data/user/roles-permissions';
import AbilityStore, { IAbilityStore } from '@/domain/core/auth/AbilityStore';
import HeaderStore from '@/domain/core/HeaderStore';
import { Permission } from '@/features/permissions/instance';
import { TUser, TUserPermission, TUserProfile, TUserRole, TUserRoleItem, TUserV2 } from '@/types/user';
import { backendApi } from '@/utils/api';

export interface IUserStore {
	profile?: TUser['profile'];
	abilityStore;
	userReadyStatus: 'loading' | 'success' | 'error';
	id: number;
	isAdmin: boolean;
	isLeader: boolean;
	updateProfile: (newAvatar: TUserProfile) => void;
	checkUserConsents: () => void;
	consentVersion?: consentVersion[];
	availableRoles?: TUserRoleItem[];
	role: TUserRole;
	internationalProjectsAvailable: boolean;
	updateEmailConfirmed(status: boolean): void;
	userSignedConsents: SelectedUserConsentDTOResponse;
	isConsentsSigned: boolean | null;
	isInternalUser: boolean;
	isEmailConfirmed: boolean;
	isThemeAllowed(themeId?: number): boolean;
}

export interface IAuthResponse extends TUserV2 {}

// src\features\admin\consents\helpers\statuses.ts
type consentVersion = {
	documentTypeId: 1 | 2 | 3;
	documentStatusId: number;
	id: number;
};

interface AvailableProjectsResponse {
	hasInternationalProjects: boolean;
	hasLeaderProjects: boolean;
}

interface RequestedUserConsentResponse {
	consentVersion: consentVersion;
	attributes: Array<{ id: number; name: string }>;
}

interface SelectedUserConsentResponse {
	id: number;
	consentDocumentVersion: {
		id: number;
		documentTypeId: number;
		name: number;
	};
	attributes: Array<{ id: number; attribute: { id: number; name: string } }>;
}

interface SelectedUserConsentPayload {
	consentDocumentVersionId: number;
	attributeIds: number[];
}

export type RequestedUserConsentDTOResponse = Array<RequestedUserConsentResponse>;

export type SelectedUserConsentDTOResponse = Array<SelectedUserConsentResponse>;

export type SelectedUserConsentDTOPayload = Array<SelectedUserConsentPayload>;

class User implements IUserStore {
	id: number;

	abilityStore: IAbilityStore;

	profile: TUser['profile'] = {} as TUser['profile'];

	role: TUserRole;

	userReadyStatus: 'loading' | 'success' | 'error' = 'loading';

	isConsentsSigned: boolean | null = null;

	isInternalUser = false;

	isEmailConfirmed = false;

	consentsVersion: consentVersion[] = [];

	internationalProjectsAvailable = false;

	leaderProjectsAvailable = false;

	userSignedConsents: SelectedUserConsentDTOResponse = [];

	availableRoles: TUserRoleItem[] = [];

	headerStore: HeaderStore;

	constructor() {
		makeAutoObservable(this);
		this.headerStore = new HeaderStore(this);
	}

	get isGlobalAdmin() {
		return this.role?.name === UserRoles.GlobalAdmin;
	}

	get isAdmin() {
		return (
			this.role?.name === UserRoles.GlobalAdmin ||
			this.role?.name === UserRoles.Admin ||
			this.role?.name === UserRoles.ThemeAdmin ||
			this.role?.name === UserRoles.InternationalAdmin
		);
	}

	get isImportAdmin() {
		return this.role?.name === UserRoles.ImportAdmin;
	}

	get isSecurityAdmin() {
		return this.role?.name === UserRoles.SecurityAdmin;
	}

	get isTracker() {
		if (!Permission.instance.ready) {
			return (
				this.role?.name === UserRoles.Tracker ||
				this.role?.name === UserRoles.ThemeTracker ||
				this.role?.name === UserRoles.InternationalTracker ||
				this.role?.name === UserRoles.InternationalTrackerExpert
			);
		}
		return this.role?.isTracker ?? false;
	}

	get isLeader() {
		return this.role?.name === UserRoles.Leader || this.role?.name === UserRoles.InternationalLeader;
	}

	get isHeadTracker() {
		return this.role?.name === UserRoles.HeadTracker;
	}

	get isTopManager() {
		return this.role?.name === UserRoles.TopManager;
	}

	get isExpert() {
		if (!Permission.instance.ready) {
			return (
				this.role?.name === UserRoles.Expert ||
				this.role?.name === UserRoles.InternationalExpert ||
				this.role?.name === UserRoles.ThemeExpert ||
				this.role?.name === UserRoles.InternationalTrackerExpert
			);
		}
		return this.role?.isExpert ?? false;
	}

	get isEmployee() {
		return this.role?.name === UserRoles.Employee;
	}

	get isManager() {
		return this.role?.name === UserRoles.Manager;
	}

	get inAdminGroup() {
		return this.isAdmin || this.isTracker || this.isHeadTracker;
	}

	get isInternational() {
		return this.role?.name.includes('international');
	}

	async init() {
		return this.fetchUserData().catch(console.error);
	}

	get currentRole() {
		return this.role;
	}

	isThemeAllowed(themeId?: number) {
		return this.role?.allowedThemesIds.includes(themeId ?? 0);
	}

	updateProfile = (newProfile: TUserProfile) => {
		this.profile = newProfile;
	};

	updateEmailConfirmed = (newStatus: boolean) => {
		this.isEmailConfirmed = newStatus;
	};

	getInitialRedirectPath() {
		const firstTab = this.headerStore?.tabsConfig.find((item) => item.visible !== false);

		if (!firstTab) {
			return '/restricted';
		}

		return firstTab.path;
	}

	// eslint-disable-next-line class-methods-use-this
	async setCurrentRole(roleId: number) {
		await backendApi.put<unknown>('/role/current_role', {
			roleId,
		});
		window.location.reload();
	}

	async fetchUserData() {
		if (typeof window === 'undefined') return;
		try {
			const { data } = await backendApi.get<IAuthResponse>('/v2/users/profile');

			if (
				((data.consentsIsSigned || data.isInternal) && data.currentRole?.name === UserRoles.Leader) ||
				data.currentRole?.name === UserRoles.InternationalLeader
			) {
				const { data: availableProjects } = await backendApi.get<AvailableProjectsResponse>(
					'/projects/available'
				);

				if (availableProjects?.hasInternationalProjects) {
					this.internationalProjectsAvailable = true;
				}

				if (availableProjects?.hasLeaderProjects) {
					this.leaderProjectsAvailable = true;
				}
			}

			if (data.currentRole && !data.currentRole.shouldWorkAsLegacyRole) {
				Permission.instance.initAbility(data.currentRole.permissions);
			}

			runInAction(() => {
				this.profile = data.profile;
				this.role = data.currentRole;
				this.id = data.id;
				this.abilityStore = new AbilityStore(this.role);
				this.isConsentsSigned = data.consentsIsSigned;
				this.availableRoles = data?.roles ?? [];
				this.isEmailConfirmed = data.emailConfirmed;
				this.isInternalUser = data.isInternal;
				this.userReadyStatus = 'success';
			});
		} catch (error) {
			const isInternational = window.location.pathname.startsWith('/international');
			const roleName = isInternational ? UserRoles.InternationalLeader : UserRoles.Leader;

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.abilityStore = new AbilityStore({
				name: roleName,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				permissions: (RolePermissions[roleName].permissions ?? []) as TUserPermission[],
			});

			this.userReadyStatus = 'error';

			console.error('Ошибка при авторизации', error);
		}
	}

	checkUserConsents = async () => {
		try {
			if (!this.isConsentsSigned) {
				const { data } = await backendApi.get<RequestedUserConsentDTOResponse>('/v2/user-consents/sign');
				this.consentsVersion = data.map((consent) => consent.consentVersion);
				return;
			}
			const { data } = await backendApi.get<SelectedUserConsentDTOResponse>('/v2/user-consents');
			this.userSignedConsents = data;
		} catch (e) {
			console.error(e);
		}
	};

	setUserConsents = async (data: SelectedUserConsentDTOPayload) => {
		await backendApi.post<unknown>('/v2/user-consents/sign', data);
		this.fetchUserData();
	};

	setConsent = (bool: boolean) => {
		this.isConsentsSigned = bool;
	};
}

export default new User();

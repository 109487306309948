import { UserRoles } from '@/data/user/role';

export type PermissionItem = {
	action: string;
	subject: string;
	condition?: any;
};

export type RoleType = {
	id: number;
	name: string;
	permissions: PermissionItem[];
};

export const RolePermissions: Record<UserRoles, RoleType> = {
	[UserRoles.Admin]: {
		id: 1,
		name: UserRoles.Admin,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'update',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Theme',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'manage',
				subject: 'StatusUploadProtocol',
			},
			{
				action: 'manage',
				subject: 'Rights',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
			{
				action: 'manage',
				subject: 'ImportProjects',
			},
			{
				action: 'read',
				subject: 'ReportsPage',
			},
		],
	},
	[UserRoles.ThemeAdmin]: {
		id: 1,
		name: UserRoles.ThemeAdmin,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'update',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Theme',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'manage',
				subject: 'StatusUploadProtocol',
			},
			{
				action: 'manage',
				subject: 'Rights',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.ImportAdmin]: {
		id: 1,
		name: UserRoles.ImportAdmin,
		permissions: [
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'Project',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'manage',
				subject: 'ImportProjects',
			},
		],
	},
	[UserRoles.GlobalAdmin]: {
		id: 1,
		name: UserRoles.GlobalAdmin,
		permissions: [
			{
				action: 'manage',
				subject: 'AdminPage',
			},
			{
				action: 'update',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'update',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Theme',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'manage',
				subject: 'StatusUploadProtocol',
			},
			{
				action: 'manage',
				subject: 'Rights',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
			{
				action: 'manage',
				subject: 'ImportProjects',
			},
			{
				action: 'read',
				subject: 'ReportsPage',
			},
		],
	},
	[UserRoles.Leader]: {
		id: 1,
		name: UserRoles.Leader,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
				condition: {
					// eslint-disable-next-line no-template-curly-in-string
					leaderId: '${user.id}',
				},
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
		],
	},
	[UserRoles.HeadTracker]: {
		id: 1,
		name: UserRoles.HeadTracker,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Theme',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.Tracker]: {
		id: 1,
		name: UserRoles.Tracker,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.ThemeTracker]: {
		id: 1,
		name: UserRoles.ThemeTracker,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.Expert]: {
		id: 1,
		name: UserRoles.Expert,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ExpertiseList',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEstimate',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.ThemeExpert]: {
		id: 1,
		name: UserRoles.ThemeExpert,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ExpertiseList',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEstimate',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.Employee]: {
		id: 1,
		name: UserRoles.Employee,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.Manager]: {
		id: 1,
		name: UserRoles.Manager,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.InternationalAdmin]: {
		id: 1,
		name: UserRoles.InternationalAdmin,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'update',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Theme',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'manage',
				subject: 'StatusUploadProtocol',
			},
			// {
			// 	action: 'manage',
			// 	subject: 'Rights',
			// },
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'manage',
				subject: 'Translation',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.InternationalLeader]: {
		id: 1,
		name: UserRoles.InternationalLeader,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
				condition: {
					// eslint-disable-next-line no-template-curly-in-string
					leaderId: '${user.id}',
				},
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.InternationalTracker]: {
		id: 1,
		name: UserRoles.InternationalTracker,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'read',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'Translation',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.InternationalExpert]: {
		id: 1,
		name: UserRoles.InternationalExpert,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ExpertiseList',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEstimate',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.InternationalTrackerExpert]: {
		id: 1,
		name: UserRoles.InternationalTrackerExpert,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'read',
				subject: 'Tracker',
			},
			{
				action: 'read',
				subject: 'ExpertiseList',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEstimate',
			},
			{
				action: 'manage',
				subject: 'Translation',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.SecurityAdmin]: {
		id: 1,
		name: UserRoles.SecurityAdmin,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'update',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'update',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'update',
				subject: 'ProjectStatus',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Theme',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'manage',
				subject: 'Tracker',
			},
			{
				action: 'manage',
				subject: 'ExpertAppointment',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'manage',
				subject: 'StatusUploadProtocol',
			},
			{
				action: 'manage',
				subject: 'Rights',
			},
			{
				action: 'manage',
				subject: 'ExpertiseEdit',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
	[UserRoles.TopManager]: {
		id: 1,
		name: UserRoles.TopManager,
		permissions: [
			{
				action: 'update',
				subject: 'ProfilePage',
				condition: 'userId',
			},
			{
				action: 'read',
				subject: 'ProfilePage',
			},
			{
				action: 'read',
				subject: 'ProjectList',
			},
			{
				action: 'read',
				subject: 'RequestPage',
			},
			{
				action: 'read',
				subject: 'StatusPage',
			},
			{
				action: 'read',
				subject: 'EstimatePage',
			},
			{
				action: 'read',
				subject: 'ExpertisePage',
			},
			{
				action: 'read',
				subject: 'ExpertList',
			},
			{
				action: 'manage',
				subject: 'Project',
			},
			{
				action: 'read',
				subject: 'UserList',
			},
			{
				action: 'read',
				subject: 'ThemeList',
			},
			{
				action: 'manage',
				subject: 'Download',
			},
			{
				action: 'read',
				subject: 'Tracker',
			},
			{
				action: 'read',
				subject: 'ExpertiseStatus',
			},
			{
				action: 'read',
				subject: 'ExpertStatistic',
			},
			{
				action: 'read',
				subject: 'ExpertDownloadProject',
			},
			{
				action: 'update',
				subject: 'ProjectOperator',
			},
		],
	},
};
